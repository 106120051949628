
<template>
  <form data-vv-scope="money-form">
    <v-text-field
      v-if="visible === true"
      type="number"
      v-on:keypress="$store.getters['app/isNumber']($event)"
      :prepend-icon="prependIcon"
      :append-icon="appendIcon"
      :outlined="outlined"
      :dense="dense"
      :label="label"
      v-model="value[valueKey]"
      :placeholder="placeholder"
      @blur="onBlurNumber"
      :hide-details="hideDetails"
      @change="
        change();
        $emit('change');
      "
      :readonly="readonly ? readonly : false"
      :min="min"
      data-vv-validate-on="blur"
      :error-messages="errors.collect('money-form.money')"
      v-validate="rules"
      data-vv-name="money"
      :disabled="disabled"
    />
    <v-text-field
      v-else
      :prepend-icon="prependIcon"
      :append-icon="appendIcon"
      :outlined="outlined"
      :dense="dense"
      :label="label"
      v-model="temp"
      data-vv-validate-on="blur"
      :error-messages="errors.collect('money-form.money')"
      v-validate="rules"
      data-vv-name="money"
      :placeholder="placeholder"
      @focus="onFocusText"
      :hide-details="hideDetails"
      :min="min"
      :max="max"
      :disabled="disabled"
      v-bind:class="[
        {
          'error--text':
            (min && value[valueKey] < min) || (max && value[valueKey] > max),
        },
      ]"
    />
  </form>
</template>

<script>
export default {
  name: "NumberWithSeparators",
  props: [
    "value",
    "valueKey",
    "label",
    "prependIcon",
    "readonly",
    "appendIcon",
    "outlined",
    "dense",
    "min",
    "disabled",
    "rules",
    "hideDetails",
    "placeholder",
    "max",
  ],
  watch: {
    value: function (newVal, oldVal) {
      // watch it
      //console.log("Prop changed: ", newVal, " | was: ", oldVal);
    },
  },
  data() {
    return {
      visible: false,
      temp: null,
    };
  },
  mounted() {
    this.change();
  },
  methods: {
    isNumber(e) {
      let char = String.fromCharCode(e.keyCode);
      if (/^[0-9.]+$/.test(char)) return true;
      else e.preventDefault();
    },
    async validate() {
      this.errors.clear();
      this.error = false;
      let form_val = await this.$validator.validateAll("money-form");

      if (
        (this.min && this.value[this.valueKey] < this.min) ||
        (this.max && this.value[this.valueKey] > this.max)
      )
        return false;

      if (form_val) return true;

      return false;
    },
    onBlurNumber() {
      this.visible = false;
    },
    onFocusText() {
      this.visible = true;
    },
    change() {
      this.errors.clear();

      this.temp = this.value[this.valueKey]
        ? this.$n(this.value[this.valueKey], "currency")
        : null;
    },
  },
};
</script>